import { Box, Grid, Stack, Typography, Button, Tooltip, Input } from "@mui/material"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { Clear, Logout, Save, Search } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoneyIcon from '@mui/icons-material/Money';
import { useEffect, useState } from "react"
import axios from "axios"
import { LinkHeader } from "../components/linkHeader"
import { ProgressBar } from 'primereact/progressbar';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment-timezone';
import CustomAxios from "../utils/CustomAxios"
import SavingsOutlinedIcon from '@mui/icons-material/Savings';
import ClearIcon from '@mui/icons-material/Clear';
import { useMediaQuery } from "react-responsive";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const SpecificDeposit = () => {
    // let api_baseUrl = "http://127.0.0.1:8000/oasis/"
    // let api_baseUrl = `${process.env.REACT_APP_OASIS_BE_URL}oasis/`
    // let api_baseUrl = "https://stage-oasis.inofii.com/oasis/"
    // let api_baseUrl = "https://www.oasis-be.kskb4391.org.my/oasis/"
    const username = window.localStorage.getItem('username')
    const navigate = useNavigate()
    const paymentTypeOptions = [
        { name: 'Monthly', code: 0 },
        { name: 'Quarterly', code: 1 },
        { name: 'Half-Yearly', code: 2 },
        { name: 'Yearly', code: 3 }
    ]
    const periodOptions = Array.from({length:7}).map((_,i)=>{return({name:`${i+1}`,code:i+1})})
    // console.log(periodOptions)
    const [details,setDetails] = useState({
        memberid: "",
        bank: null,
        depositDate: "",
        depositAmt: "",
        bankName: null,
        chequeNo: "",
        narration: "",
        period: null,
        intRate: "",
        intMon:"",
        intYear:"",
        paymentType: null,
        dateOfExpiry: "",
        createdby: ""
    })
    const [memberName,setMemberName] = useState('')
    const [bankOptions,setBankOptions] = useState('')
    const [depositBankOptions,setDepositBankOptions] = useState('')
    const [receiptNo,setReceiptNo] = useState('')
    const [depositNo,setDepositNo] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const [errors,setErrors] = useState('')
    const isMobile = useMediaQuery({ maxWidth: 600 })
   // const [isSaving, setSaving] = useState(false);
       useEffect(()=>{
        try{
            setIsLoading(true)
            CustomAxios.get("oasis/specificDeposit/").then((res)=>{
                let res_data = res.data
                // console.log(res_data)
                let bn_arr = []
                let dpBn_arr = []
                res_data?.bankName.map((bn,bn_i)=>bn_arr.push({name:bn,code:bn}))
                res_data?.depositBank.map((bn,bn_i)=>dpBn_arr.push({name:bn,code:bn}))
                setBankOptions(bn_arr)
                setDepositBankOptions(dpBn_arr)
                setIsLoading(false)
            }).catch(err=>{
                //console.error(err)
                setIsLoading(false)
            })
        }
        catch(err){
            console.err(err)
        }
        
    },[])

    const handleChange = (e)=>{
        // console.log(e.target.name, e.value)
        let dummy_details = {...details}
        // setMemberName("")
        dummy_details[e.target.name] = e.target.value
        dummy_details.membername=""
        if (e.target.name=="period"){
            CalculateExpiryDate(e.value)
        }
        else{
            setDetails(dummy_details)
        }
        //console.log(details.depositDate)
        // console.log(dummy_details)
        //setDetails(dummy_details)
    }
    const GetMemberDetails = async(e)=>{
        e.preventDefault()
        if(!isNaN(e.target.value) && Number.isInteger(parseInt(e.target.value))||(memberName!="")){
            let payload
            if (details.memberid!=""){
                //console.log(details.memberid)
                setMemberName("")
                payload = {
                    "memberid":parseInt(details.memberid)
                }
            }
                    if (memberName!=""){
                        payload={
                        "name":memberName}
                    }
            

            try{
                //errors.member= "";
                setIsLoading(true);
                CustomAxios.post(`oasis/getmember/`, payload).then((data)=>{
                    // console.log(data)
                    let res_data = data.data
                    setMemberName(data.data.membername)
                    details.memberid = data.data.memberid
                setIsLoading(false);
                    
                }).catch(err=>{
                    //console.error(err)
                    setIsLoading(false);
                errors.member= "not available";
                    
                })
            }
            catch(err){
               // console.error(err)
                errors.member= "not available";
            }
        }
    }
    
    const CalculateExpiryDate =(period)=>{
        // console.log(period)
        if(period.code){
            // console.log("we are here")
            let noOfMonths = period.code * 12
            let dpDate = new Date(details.depositDate)
            let expiry_date = new Date( dpDate.setMonth(dpDate.getMonth() + noOfMonths) )
            //console.log(expiry_date)
            if(details.intRate && details.depositAmt){
                console.log("we are in if")
                let int = details.intRate
                let noOfMonths = parseInt(period.code) * 12
                let total_int = null
                let month_int = null
                
                total_int = (Math.round(parseInt(details.depositAmt) * parseInt(int) * 100) / 100) / 100 * (Math.round(noOfMonths / 12 * 100) / 100)
                month_int = Math.round((total_int / noOfMonths) * 100) / 100
                setDetails({
                    ...details,
                    period:period,
                    intRate:int,
                    intMon:month_int,
                    intYear:total_int,
                    dateOfExpiry:expiry_date
                })
            }
            else{
                setDetails({
                    ...details,
                    period:period,
                    dateOfExpiry:expiry_date
                })
            }
        }
    }

    const CalculateInterestRate = (val)=>{
        
        // console.log("---------coming",details.depositAmt , e,details.period.code)
        let int = parseInt(val)
        isNaN(int)?setDetails({...details,intRate:val}):setDetails({...details,intRate:int})
                if(details.depositAmt && int && details.period.code){
            let noOfMonths = parseInt(details.period.code) * 12
            let total_int = null
            let month_int = null

            total_int = (Math.round(parseInt(details.depositAmt) * parseInt(int) * 100) / 100) / 100 * (Math.round(noOfMonths / 12 * 100) / 100)
            month_int = Math.round((total_int / noOfMonths) * 100) / 100
            // console.log(month_int,total_int)
            setDetails({
                ...details,
                intRate:int,
                intMon:month_int,
                intYear:total_int
            })
        }
    }

    const handleSave = (e)=>{
        e.preventDefault()
        let dummy_details = {...details}
        dummy_details.depositDate = moment(dummy_details.depositDate).format("YYYY-MM-DDTHH:mm:ss[Z]")
        dummy_details.dateOfExpiry = moment(dummy_details.dateOfExpiry).format("YYYY-MM-DDTHH:mm:ss[Z]")
        dummy_details.createdby = username
        dummy_details.membername=memberName
        dummy_details.depositAmt = parseInt(dummy_details.depositAmt)
        Object.keys(dummy_details).map((key)=>{
            if(typeof(dummy_details[key]) === "object" && dummy_details[key].name){
                // console.log(key,dummy_details[key].code)
                dummy_details[key] = dummy_details[key].code
            }
        })
                let empty_values = Object.keys(dummy_details).filter(key => !dummy_details[key])
        if(((empty_values.length === 1 && empty_values[0] === "paymentType") || (empty_values.length === 0)) && !depositNo){
            // console.log(dummy_details)
            try{
                setErrors("")
                                setIsLoading(true)
                CustomAxios.post("oasis/specificDeposit/",dummy_details).then((res)=>{
                    let res_data = res.data
                    // console.log("---api res----",res_data)
                    setDepositNo(res_data.depositId)
                    setReceiptNo(res_data.receiptId)
                    setIsLoading(false)
                                    }).catch(err=>{
                    console.error(err)
                    setIsLoading(false)
                })
            }
            catch(err){
                console.error(err)
            }
            
        }
        else{
            let dummy_errors = {}
            empty_values.map((key,key_i)=>dummy_errors[key] = "Required")
            setErrors(dummy_errors)
            //console.info(empty_values)
            //console.info(dummy_details)
        }
    }

    const handleSearch = (e)=>{
        e.preventDefault()
        if(depositNo){
            try{
                setErrors("")
                setIsLoading(true)
                CustomAxios.get(`oasis/specificDeposit/?dno=${depositNo}`).then((res)=>{
                    let res_data = res.data
                    if(res_data.memberid){
                        if (res.paymentType>3){
                            res.paymentType=3
                        }
                        depositBankOptions.map((obj)=>obj.name === res_data.bank && (res_data.bank = obj))
                        periodOptions.map((obj)=>obj.name == res_data.period && (res_data.period = obj))
                        bankOptions.map((obj)=>obj.name === res_data.bankName && (res_data.bankName = obj))
                        paymentTypeOptions.map((obj)=>{
                            let code = res_data.paymentType
                            obj.code === code && (res_data.paymentType = obj)
                        })
                        res_data.depositDate = new Date(res_data.depositDate)
                        res_data.dateOfExpiry = new Date(res_data.dateOfExpiry)
                        setMemberName(res_data.memberName)
                        // console.log(res_data)
                        setDetails(res_data)
                        setReceiptNo(res_data.receiptNo)
                    }
                    setIsLoading(false)
                }).catch(err=>{
                    //console.error(err)
                    setIsLoading(false)
                })
            }
            catch(err){
                console.log(err)
            }
        }
        else{
            setErrors({"depositNo":true})
        }
    }

    const handleClear = (e)=>{
        setDetails({
            memberid: "",
            bank: null,
            depositDate: "",
            depositAmt: "",
            bankName: null,
            chequeNo: "",
            narration: "",
            period: null,
            intRate: "",
            intMon:"",
            intYear:"",
            paymentType: null,
            dateOfExpiry: "",
            createdby: ""
        })
        setMemberName("")
        setDepositNo("")
        setReceiptNo("")
        setErrors('')
    }
    const handleclick = (e) => {
        e.preventDefault()
        if(e.key=="Enter"){
            GetMemberDetails(e)
        }
    }
    const handleDownloadReceipt = (e)=>{
        e.preventDefault()
        CustomAxios.get("oasis/download-SDreceipt-all/"+depositNo,{ responseType: 'blob' }).then((res)=>{
                    
            if (res.data) {
                // Create a URL for the PDF blob
                const fileURL = URL.createObjectURL(res.data);
                //console.log(fileURL)
    
                // Open the PDF in a new window/tab
                const win = window.open(fileURL, '_blank');
            }
        }).catch((err)=>console.error(err))

    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e); 
        }
      };
    
    return (
        <Box sx={{width:"100%",backgroundColor:isMobile&&"#F8FAFC",height:isMobile?"100vh":"calc(100vh - 72px)", marginTop: "60px",}}>
            {/* <LinkHeader/> */}
            {
                isLoading?
                    <Stack rowGap="10px" sx={{width:"100%",height:"87vh",alignItems:"center",justifyContent:"center"}}>
                        <Typography>Please Wait</Typography>
                        <ProgressBar mode="indeterminate" style={{ width:"25%", height: '6px' }}/>
                    </Stack>
                :
                <Box sx={{display:"flex",flexDirection:"column",height:isMobile?"100vh":"calc(100vh - 148px)"}}>
                    <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                     <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"100%",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                            <SavingsOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#1E293B",width:"100%"}}>Specific Deposit</Typography>
                        </Box>
                        <Box sx={{display:"flex",gap:"20px"}}>
                        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    
               </Box>
                        </Box>
                        {/* <Button  onClick={searchMemberStatus?()=>clearAllStates():(e)=> handleSignup(e)}  variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><PersonAddAltOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Add Member</Typography></Button> */}
                    </Box>
                </Box>
                    <Grid container spacing="20px" sx={{padding:"20px 30px",width:"100%"}}>
                        <Grid container item spacing="20px" sx={{alignItems:"center"}}>
                            <Grid item md={2}>
                                <Typography>Bank (Deposit) <Typography component="span" sx={{color:"red"}}>*</Typography> :</Typography>
                                <Dropdown showClear placeholder="Select Bankdeposit" className={errors.bank ? "p-invalid":""} name="bank" options={depositBankOptions} optionLabel="name" value={details.bank}  onChange={(e)=>handleChange(e)} style={{color:"#CBD5E1",fontSize:"14px", padding: "8px 12px", width: "100%",maxWidth:"280px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",height:"32px",display:"flex",alignItems:"center"}}/>
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Deposit Date <Typography component="span" sx={{color:"red"}}>*</Typography>  :</Typography>
                                <Box sx={{display:"flex",alignItems:"center", maxWidth:"280px",width: "100%",position:"relative"}}>
                                <Calendar className={errors.depositDate ? "p-invalid":""} dateFormat="dd-mm-yy" name="depositDate" value={details.depositDate}  onChange={(e)=>{handleChange(e)}} style={{padding: "0px", maxWidth:"280px",width: "100%", height: "36px", display: "flex", alignItems: "center" ,borderRadius:"4px",fontSize:"14px"}} placeholder='dd-mm-yyyy'/>
                                <CalendarMonthIcon sx={{position:"absolute",right:"12px",color:"#64748B",height:"17px",width:"15px"}}/></Box>
                            
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Receipt No :</Typography>
                                <InputText readOnly  style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}} value={receiptNo} />
                            </Grid>
                            <Grid item md={4}>
                                <Stack direction="row" sx={{alignItems:"flex-end",gap:"10px"}}>
                                    <Box sx={{width:"100%"}}>
                                        <Typography>Deposit No :</Typography>
                                        <InputText className={errors.depositNo?"p-invalid":""} style={{ padding: "8px 12px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}} value={depositNo} onChange={(e)=>setDepositNo(e.target.value)} onKeyDown={(e)=>handleKeyPress(e)}/>
                                    </Box>
                                    <Box>
                                        {
                                            depositNo?
                                                <Button  onClick={(e)=>handleDownloadReceipt(e)}><DownloadForOfflineIcon/>Download Receipt</Button>
                                            : null
                                        }
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item spacing="20px" sx={{alignItems:"center"}}>
                            <Grid item md={2}>
                                <Typography>Member No <Typography component="span" sx={{color:"red"}}>*</Typography> :</Typography>
                                <InputText  className={errors.memberid ? "p-invalid":""} name="memberid" value={details.memberid}  onChange={(e)=>handleChange(e)} onKeyUp={(e)=>handleclick(e)} onBlur={(e)=>GetMemberDetails(e)} style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}/>
                            </Grid>
                            <Grid item md={8}>
                                <Typography>Member Name :</Typography>
                                <InputText style={{width:"100%"}} value={memberName} onChange={(e)=>setMemberName(e.target.value)} onKeyUp={(e)=>handleclick(e)} onBlur={(e)=>GetMemberDetails(e)}/>
                            </Grid>
                        </Grid>
                        <Grid container item spacing="20px" sx={{alignItems:"center"}}>
                            <Grid item md={2}>
                                <Typography>Deposit Amount <Typography component="span" sx={{color:"red"}}>*</Typography>  :</Typography>
                                <InputText  className={errors.depositAmt ? "p-invalid":""} name="depositAmt" value={details.depositAmt}  onChange={(e)=>handleChange(e)} style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}/>
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Period (years) <Typography component="span" sx={{color:"red"}}>*</Typography>  :</Typography>
                                <Dropdown showClear placeholder="Select period" className={errors.period ? "p-invalid":""} name="period"  options={periodOptions} optionLabel="name" value={details.period}  onChange={(e)=>{handleChange(e)}} style={{color:"#CBD5E1",fontSize:"14px", padding: "8px 12px", width: "100%",maxWidth:"280px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",height:"32px",display:"flex",alignItems:"center"}}/>
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Bank Name <Typography component="span" sx={{color:"red"}}>*</Typography> :</Typography>
                                <Dropdown showClear placeholder="Select Bankname" className={errors.bankName ? "p-invalid":""} name="bankName"  options={bankOptions} optionLabel="name" value={details.bankName}  onChange={(e)=>handleChange(e)} style={{color:"#CBD5E1",fontSize:"14px", padding: "8px 12px", width: "100%",maxWidth:"280px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",height:"32px",display:"flex",alignItems:"center"}}/>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>Cheque No <Typography component="span" sx={{color:"red"}}>*</Typography> :</Typography>
                                <InputText  className={errors.chequeNo ? "p-invalid":""} name="chequeNo" value={details.chequeNo}  onChange={(e)=>handleChange(e)} style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}/>
                            </Grid>
                        </Grid>
                        <Grid container item spacing="20px" sx={{alignItems:"center"}}>
                            <Grid item md={2}>
                                <Typography>Int Rate <Typography component="span" sx={{color:"red"}}>*</Typography>  :</Typography>
                                
                                <InputText  className={errors.intRate ? "p-invalid":""} name="intRate" value={details.intRate}  onChange={(e)=>{CalculateInterestRate(e.target.value)}} style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}/>
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Int Per Month :</Typography>
                                <InputText readOnly value={details.intMon} style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}/>
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Int Per Year :</Typography>
                                <InputText readOnly value={details.intYear} style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}/>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>Payment Type <Typography component="span" sx={{color:"red"}}>*</Typography>  :</Typography>
                                <Dropdown showClear placeholder="Select Paymenttype"  className={errors.paymentType ? "p-invalid":""} name="paymentType"  value={details.paymentType}  onChange={(e)=>handleChange(e)} options={paymentTypeOptions} optionLabel="name" style={{color:"#CBD5E1",fontSize:"14px", padding: "8px 12px", width: "100%",maxWidth:"280px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",height:"32px",display:"flex",alignItems:"center"}}/>
                            </Grid>
                        </Grid>
                        <Grid container item spacing="20px" sx={{alignItems:"center"}}>
                            <Grid item md={2}>
                                <Typography>Date Of Expiry :</Typography>
                                <Box sx={{display:"flex",alignItems:"center", maxWidth:"280px",width: "100%",position:"relative"}}>
                                <Calendar style={{padding: "0px",  maxWidth:"280px",width: "100%", height: "36px", display: "flex", alignItems: "center" ,borderRadius:"4px",fontSize:"14px"}} placeholder='dd-mm-yyyy' className={errors.dateOfExpiry ? "p-invalid":""} dateFormat="dd-mm-yy" name="dateOfExpiry" value={details.dateOfExpiry}  onChange={(e)=>handleChange(e)} />
                                <CalendarMonthIcon sx={{position:"absolute",right:"12px",color:"#64748B",height:"17px",width:"15px"}}/></Box>
                            </Grid>
                            <Grid item md={8}>
                                <Typography>Narration <Typography component="span" sx={{color:"red"}}>*</Typography>  :</Typography>
                                <InputText  className={errors.narration ? "p-invalid":""} style={{width:"100%"}} name="narration" value={details.narration}  onChange={(e)=>handleChange(e)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Stack direction="row" gap="16px">
                            <Button variant="contained" onClick={(e)=>{handleSave(e)}} 
                            sx={{mt:"1rem", background: "#fff", borderColor: "##fff", color: "#288d2c", 
                            // "&:hover": { borderColor: "#fff", background: "#fff" }, 
                            fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<Save />}>SAVE</Button>
                        <Button variant="contained" onClick={(e)=>{handleSearch(e)}} 
                        sx={{mt:"1rem", background: "#fff", borderColor: "##fff", color: "#288d2c", 
                        // "&:hover": { borderColor: "#fff", background: "#fff" }, 
                        fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<Search />}>SEARCH</Button>
                        <Button variant="contained" onClick={(e)=>{handleClear(e)}} 
                        sx={{mt:"1rem", background: "#fff", borderColor: "##fff", color: "#288d2c", 
                        // "&:hover": { borderColor: "#fff", background: "#fff" }, 
                        fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<Clear />}>CLEAR</Button>
                        
                    </Stack> */}       
                </Box>
            }
            <Box sx={{position:"sticky",bottom:0}}>
                 <Box sx={{height:"76px",width:"100%",display:"flex",justifyContent:"flex-end",gap:"24px",padding:"14px 30px",background:"#F1F5F9"}}>
                                    <Box sx={{width:"406px",display:"flex",gap:"24px"}}>
                                    <Button variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}  onClick={(e)=>{handleClear(e)}}>Clear</Typography></Button>
                                    <button type="submit" variant="contained"  style={{border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor: "pointer" }} >
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF"}} onClick={(e)=>{handleSave(e)}} >Submit</Typography>
                                  </button>
                                    </Box>
                              
                </Box> 
                 </Box>
        </Box>
    )
}