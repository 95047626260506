import { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { LinkHeader } from "../../components/linkHeader";

import { Calendar } from 'primereact/calendar';
import CustomAxios from "../../utils/CustomAxios";


export function AccStatement() {
    const [memberId, setMemberId] = useState('');
    const [selectedYear, setSelectedYear] = useState('2024');

    console.log("setSelectedYear", selectedYear);

    const handleFetchAndPrintReceipt = async () => {
        try {
            const year = selectedYear.getFullYear();
            const payload = {
                dr: [
                    `${year}-01-01 00:00:00`,
                    `${year}-12-31 23:59:59`
                ],
                financial_year: year
            };
            // URL to your Django view that generates the PDF
            const receiptUrl = `oasis/member_acc_statement/${memberId}/`;

            // Fetch the PDF receipt
            const response = await CustomAxios.post(receiptUrl, payload, { responseType: 'blob' });
            if (response.data) {
                // Create a URL for the PDF blob
                console.log("Created", response.data)
                const fileURL = URL.createObjectURL(response.data);

                // Open the PDF in a new window/tab
                const win = window.open(fileURL, '_blank');

                // Optionally, automatically trigger the print dialog in the new window
                win.onload = () => {
                    win.focus();
                    win.print();
                };
            }
        } catch (error) {
            console.error("Error fetching the receipt: ", error);
        }
    };

    return (
        <Box>
            {/* <LinkHeader /> */}
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                gap: '20px', 
                padding: '20px' 
            }}>
                <Calendar 
                    value={selectedYear} 
                    onChange={(e) => setSelectedYear(e.value)} 
                    view="year" 
                    dateFormat="yy" 
                    sx={{ width: '200px' }} // Adjust width as needed
                />
                <Calendar 
                    value={selectedYear} 
                    onChange={(e) => setSelectedYear(e.value)} 
                    view="year" 
                    dateFormat="yy" 
                    sx={{ width: '200px' }} // Adjust width as needed
                />
                <TextField
                    label="Member ID"
                    variant="outlined"
                    value={memberId}
                    onChange={(e) => setMemberId(e.target.value)}
                    sx={{ width: '200px' }} // Adjust width as needed
                />
                <Button
                    variant="contained"
                    onClick={handleFetchAndPrintReceipt}
                    sx={{ width: '200px' }} // Adjust width as needed
                >
                    Fetch and Print Statement
                </Button>
            </Box>

        </Box>
    );
}
