import {
    Box,
    Button,
    IconButton,
    MenuItem,
    Select,
    Typography,
  } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import OverviewBanner from '../assets/icons/AIQ_Koop-Primary.png';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { useSelector } from "react-redux";

  export function Overview() {
    const navigate = useNavigate();
    const open = useSelector((state) => state.executive.open);

    return (<>
        {/* <Box component='img' sx={{width: { xs: "200px", sm: "250px", md:"450px" }}} src={OverviewBanner} /> */}
        <Box sx={{marginTop: '64px', width: open?"calc(100vw - 279px)":"calc(100vw - 55px)", height: 'calc(100vh - 64px)', display:"flex", flexDirection:"column", alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{width: '100%', display:"flex", flexDirection:"row", justifyContent: 'space-around'}}>
        <Box sx={{display:"flex", flexDirection:"column", cursor:"pointer", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/add_member')}>
        <GroupsOutlinedIcon sx={{fontSize:"164px",fontWeight:600, color:"#288d2c", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease"}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Member Details</Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", cursor:"pointer", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/Receipts')}>
        <ReceiptOutlinedIcon sx={{fontSize:"164px",fontWeight:600, color:"#8d2c2c", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease",}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Receipts</Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", cursor:"pointer", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/payments')}>
        <PaymentsOutlinedIcon sx={{fontSize:"164px",fontWeight:600, color:"#2c4c8d", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease",}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Payments</Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", cursor:"pointer", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/loan')}>
        <ReceiptLongOutlinedIcon sx={{fontSize:"164px",fontWeight:600, color:"#8d8d2c", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease",}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Loan</Typography>
        </Box>
        </Box>
        <Box sx={{width: '100%', display:"flex", flexDirection:"row", cursor:"pointer", justifyContent: 'space-around'}}>
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/specificDeposit')}>
        <SavingsOutlinedIcon sx={{fontSize:"164px",fontWeight:600, color:"#8d5c2c", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease",}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Specific Deposit</Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", cursor:"pointer", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/dividend')}>
        <StoreOutlinedIcon sx={{fontSize:"164px",fontWeight:600, color:"#2c8d8d", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease",}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Dividend</Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", cursor:"pointer", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/deduction')}>
        <PriceChangeOutlinedIcon sx={{fontSize:"164px",fontWeight:600, color:"#5c2c8d", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease",}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Deduction</Typography>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", cursor:"pointer", alignItems:"center", padding:"8px", gap:"8px"}} onClick={()=>navigate('/account-statement')}>
        <AccountBalanceIcon sx={{fontSize:"164px",fontWeight:600, color:"#2c3a8d", "&:hover":{transform: "scale(1.1)"},transition: "transform 0.3s ease",}}/>
        <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:400,lineHeight:"28px",color:"#101010"}}>Account Statement</Typography>
        </Box>
        </Box>

        </Box></>
        
    );
  }
