
import { CutOff } from "../components/Cut-Off"
import { ExecutiveSideBar } from "../components/ExecutiveSideBar"
import { Box, Typography } from "@mui/material"
import { PrimeReactProvider } from 'primereact/api';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import '../styles/theme.css'
import { useSelector } from "react-redux";
import { Form } from "../components/form";
import { FirstPage } from "../components/firstPage";
import axios from 'axios';
import NewMemberReport from "../components/newMemberReport";
import { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ReceiptIssued from "../components/receiptReeived";
import WithdrawalReport from "../components/withdrawal";
import AgeingMember from "../components/AgingMember";
import NomineeStatus from "../components/NomineeStatus";
import { Logout } from '@mui/icons-material';
import {Button, Tooltip } from "@mui/material";
import { useState} from "react";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoneyIcon from '@mui/icons-material/Money';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LinkHeader } from "../components/linkHeader";
export const ExecutiveSummery = () => {
    const navigate = useNavigate()
    const active_tab = useSelector(state => state.executive.sideBarActiveTab)
    const cutoffData = useSelector(state => state.executive.cutoffData)
    const newMemberData = useSelector(state => state.executive.newMemberData)
    const receiptIssued=useSelector(state=>state.executive.receiptIssuedData)
    const withdrawalData = useSelector(state => state.executive.withdrawalData)
    const AgeingMemberData = useSelector(state => state.executive.AgeingMemberData)
    const NomineeStatusData=useSelector(state=>state.executive.NomineeStatusData)
    const [applyDisabled,setApplyDisabled]=useState(false)

    useEffect(()=>{
        let member_role = JSON.parse(window.sessionStorage.getItem('user_details'))
        if (member_role && member_role["member_role"]=="member"){
            navigate("/login")
        }
        console.log("in exceu summary----",active_tab,newMemberData)
    })

    const handleLogout = () => {
        // console.log('Logout clicked');
        // dispatch(updateToggleBarActiveTab('dashboard'))
        // Clear localStorage
        sessionStorage.clear();
        navigate('/login');
    };

    // const handleReporting = () => {
    //     setApplyDisabled(true)
    //     const url = "https://dnaviz.aipeople.arivu-iq.com/login"
    //     window.location.href = url;
    //     // If you have a payload, uncomment and provide it here
    //     // const payload = { /* your payload data */ };
    //     axios.post('https://www.oasis-be.kskb4391.org.my/oasis/MemberLogin/')
    //     //axios.post('http://127.0.0.1:8000/oasis/reporting/')
    //       .then(response => {
    //         // Handle API response as needed
    //         console.log('API Response:', response.data);
      
    //         // Do something with the auth_data if needed outside this function
    //         const auth_data = response.data;
    //         if (auth_data.message=="success"){
    //             const url = "https://dnaviz.aipeople.arivu-iq.com/login"
    //             window.location.href = url;
    //         }
    //         // For example, return the auth_data or resolve a promise with it
    //         // return auth_data;
    //       })
    //       .catch(error => {
    //         console.error('Error:', error);
            
    //         // Optionally, you might want to rethrow the error or handle it differently
    //         // throw error;
    //       });
    //   };

    return (
        <PrimeReactProvider>
            <Box sx={{ display: "flex", flexDirection: "column",width:"100%", marginTop:"60px"  }}>
                {/* <Box sx={{ background: 'var(--pallate-green-500, #288d2c)', padding: "18px 16px", display: "flex", alignItems: "center" ,justifyContent:"space-between"}}>
                    <Typography sx={{ fontSize: "20px", fontWeight: 600, lineHeight: "28px", color: "#fff" }}>Executive Summary</Typography>
                    <Box sx={{display:"flex",alignItems:"centr",gap:"16px"}}>
                    <Button component={Link} to="/loan" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<MoneyIcon />}>Loan</Button>
                    <Button component={Link} to="/payments" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<MoneyIcon />}>Payments</Button>

                    <Button component={Link} to="/Receipts"  variant="filled" sx={{background:"#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff",background:"#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<ReceiptLongIcon />}>Receipts</Button>
                    <Button variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} onClick={handleReporting} startIcon={ applyDisabled ? <i className="pi pi-spin pi-spinner" /> :<BarChartIcon />}>Reporting</Button>
                    <Button component={Link} to="/add_member"  variant="filled" sx={{background:"#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff",background:"#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }}startIcon={<PersonAddIcon />} >Add Member</Button>
                    <Tooltip title="Logout" arrow>
                        <Button
                        sx={{ color: "#fff" }}
                        onClick={handleLogout}
                        >
                        <Logout />
                        </Button>
                    </Tooltip>
                    </Box>
                </Box> */}
                {/* <LinkHeader/> */}
                <Box sx={{ display: "flex", }}>
                    {/* <ExecutiveSideBar /> */}
                    {active_tab == "CO" && cutoffData.length > 0 ?
                        <CutOff /> : 
                        active_tab == "NMC" && newMemberData.length > 0 ?
                        <NewMemberReport /> :
                        active_tab == "RI" && receiptIssued.length > 0 ?
                        <ReceiptIssued/>:
                        active_tab == "WD" && withdrawalData.length > 0 ?
                        <WithdrawalReport/>:
                        active_tab=="AM" && AgeingMemberData.length>0?
                        <AgeingMember/>:
                        active_tab=="NS" && NomineeStatusData.length>0?
                        <NomineeStatus/>
                        : < Form api={active_tab=="NMC"?"NMC":active_tab=="CO"?"CO":active_tab=="RI"?"RI":active_tab=="WD"?"WD":active_tab=="AM"?"AM":active_tab=="NS"?"NS":""}/>
                    }


                </Box>


            </Box>
        </PrimeReactProvider>


    )


}