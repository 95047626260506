import { CheckOutlined, Logout } from "@mui/icons-material";
import { Box, Button, MenuItem, Select, Typography,Tooltip,Grid, Stack, FormLabel, Modal, TextField, FormControlLabel, Radio, RadioGroup, CircularProgress } from "@mui/material";
// import { Tooltip } from "primereact/tooltip";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoneyIcon from '@mui/icons-material/Money';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
// import { updateMasterInfo } from "../../redux/userDetails";
// import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Dropdown } from 'primereact/dropdown';
import { Column } from "primereact/column";
import { LinkHeader } from "../../components/linkHeader";
import { ProgressBar } from "primereact/progressbar";
import CustomAxios from "../../utils/CustomAxios";
import { pmt } from 'financial';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { useMediaQuery } from "react-responsive";
import {LoanList} from "../../pages/LoanList.js";
import ClearIcon from '@mui/icons-material/Clear';

export function Loan() {
    const [memberNo, setMemberNo] = useState("")
    const [gaur, setGaur] = useState("")
    const [memberName, setMemberName] = useState("")
    const [LoanNo, setLoanNo] = useState("")
    const [date, setDate] = useState("")
    const [chequedate, setChequeDate] = useState("")
    const [checkbox, setCheckbox] = useState(false)
    const [loanType, setLoanType] = useState("")
    const [totalLoanAmount, settotalloanAmount] = useState("")
    const [toggle, setToggle] = useState("Master Information")
    const [gaurantorMemberNo, setGaurantorMemberNo] = useState("")
    const [gaurantorMemberName, setGaurantorMemberName] = useState("")
    const [gaurantorIcNo, setGaurantorIcNo] = useState("")
    const [memberList, setMemberList] = useState([])
    const [currentMember, setCurrentMember] = useState([])
    const [banks, setBanks] = useState([]);
    const [newdate, setNewDate] = useState("")
    const [loanCheckbox, setLoanCheckbox] = useState(false)
    const [Gaurantor,setgaurantor] = useState([])
    //loan details
    const [factor,setFactor] = useState("Straight Line")
    const [principal,setPrincipal] = useState(0)
    const [bankname,setBankName] = useState(null)
    const [years,setYears] = useState(0)
    const [interest,setInterest] = useState(0)
    const [narration,setNarration] = useState("")
    const [selTransDet, setSelTransDet] = useState("")
    const [processingFee,setProcessingFee] = useState(0)
    const [stmpingFee,setStampingFee] = useState(0)
    const [Insurance,setInsurance] = useState(0)
    const [LegalFee,SetLeagalFee] = useState(0)
    const [selectedSD, setSelectedSD] = useState([])
    const [membershipFee,settMembershipFee] = useState(0)
    const [upfrontDeduction,setUpfrontDeduction] = useState(0)
    const [servicePercentage,setServicePercentage] = useState(0)
    const [serviceCharges,setServiceCharges] = useState(0)
    const [expirydate, setExpiryDate] = useState(0)
    const [deduction, setDeduction] = useState(0)
    const [chequeAmount, setChequeAmount] = useState(0)
    const [chequeNo, setChequeNo] = useState("")
    const [principalpm, setPrincipalPM] = useState(0)
    const [InterestAmt, setInterestAmt] = useState(0)
    const [Inserestpm, setInterestPM] = useState(0)
    const [loandetails, setLoandetails] = useState([{"header":"Principal", "Full Tenure":0, "Per Month":0},{"header":"Interest", "Full Tenure":0, "Per Month":0},{"header":"Total Loan", "Full Tenure":0, "Per Month":0}])
    const [loanResponse,setLoanRes]=useState({})
    const [guarantorSuccess,setGaurantorsuccess]=useState(false)
    const [isLoading,setLoading] = useState(false)
    const [modalLoading, setModalLoading] = useState(false);
    const [errors,setErrors] = useState({guarentor:"",member:"",loanId:""})
    const [showMessage, setShowMessage] = useState(false);
    const [loanAmtMon,setLoanAmtMon]=useState('')
    const [loanList,setLoanList ] = useState([])
    const [guarantorList,setGuarantorList ] = useState([])
    const [guarantorForList,setguarantorForList ] = useState([])
    const [loanNoForGuarantor,setloanNoForGuarantor] = useState("")
    const [selectedOption, setSelectedOption] = useState('');
    const [amount, setAmount] = useState('');
    const [selectedLoan, setSelectedLoan] = useState(null);
    const [penNarration, setPenNarration] = useState('');
    const [totalPayableAmount, setTotalPayableAmount] = useState('');
    const [userNotTakenLoanTypes, setUserNotTakenLoanTypes] = useState([]);
    const [loanIdList, setLoanIdList] = useState([]);
    const [proceedWithLoanDetails, setProceedWithLoanDetails] = useState(false);
    const [loanTrans, setLoanTrans] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoanSel, setIsLoanselected] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 600 })
    const [active,setActive] = useState("Create Loan")
    // Modal states
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onRowSelect = (event) => {
        setProceedWithLoanDetails(true)
        setIsLoanselected(true)
        console.log(event)
        console.log(userNotTakenLoanTypes)
        const loanType = ["Education Loan1", "Personal Loan2","Housing Loan3", "Business Loan4", "Angkasa Loan5", "Angkasa Tung6"];
        let selected = event.data
        let trans_selected = loanTrans.filter(item=>item.transdetailid==event.data.transdetailsid)
        let gauran_selected = guarantorList.filter(item=>item.loanno==selected.loanid)
        console.log(trans_selected)
        console.log(gauran_selected)
        setLoanNo(selected.loanid)
        setloanNoForGuarantor(selected.loanid)
        setDate([new Date(selected.loanstartdate)])
        setSelTransDet(selected.transdetailsid)
        setLoanType(selected.loantype)
        //setUserNotTakenLoanTypes(prev=>[...prev, loanType[selected.loantype-1]])
        settotalloanAmount(selected.principalamount)
        setNewDate(handleChequedate(selected.loanstartdate))
        setExpiryDate(handleChequedate(selected.loanexpirydate))
        setYears(selected.noofinstalment/12)
        setInterest(selected.rateofinterest)
        setProcessingFee(selected.processingfee)
        setStampingFee(selected.stampingfee)
        setInsurance(selected.insuranceamount)
        SetLeagalFee(selected.legalfee)
        settMembershipFee(selected.membershipfee)
        setUpfrontDeduction(selected.angkasaupfrontdeduction)
        setServicePercentage(selected.serviceinterestrate)
        setServiceCharges(selected.servicecharge)
        setCheckbox(selected.diminishingbalance)
        setSelectedSD(gauran_selected)
        // if (gauran_selected.length>0){
        // setGaurantorIcNo(gauran_selected[0].guarantoricno)
        // setGaurantorMemberName(gauran_selected[0].guarantorname)
        // setGaurantorMemberNo(gauran_selected[0].memberid)
        // setloanNoForGuarantor(gauran_selected[0].loanno)}
        // console.log(trans_selected.bankname!=="")
        console.log((typeof(trans_selected[0]?.bankname) === 'string') && trans_selected[0]?.bankname!=="")
        console.log(!(typeof(trans_selected[0]?.bankname) === 'string') && trans_selected[0]?.bankname!==null)
        console.log(((typeof(trans_selected[0]?.bankname) === 'string') && trans_selected[0]?.bankname!=="")||trans_selected[0]?.bankname!==null)
        if (((typeof(trans_selected[0]?.bankname) === 'string') && trans_selected[0]?.bankname!=="")||(!(typeof(trans_selected[0]?.bankname) === 'string') && trans_selected[0]?.bankname!==null)){
            console.log("we are here")
            setLoanCheckbox(true)
            setChequeNo(trans_selected[0].chequeno)
            setBankName(trans_selected[0].bankname)
            setChequeDate([new Date (trans_selected[0].chequedate)])
        }
    };


    console.log(loanType )
    const clearMasterInformation = (event) => {
        event.preventDefault()
        setIsLoanselected(false)
        setLoanNo("")
        setDate("")
        setSelTransDet("")
        setLoanType("")
        settotalloanAmount("")
        setNewDate("")
        setCheckbox(false)
        setLoanList([])
        setMemberNo("")
        setMemberName("")
    };
    
    const onRowUnselect = (event) => {
        setIsLoanselected(false)
        setLoanNo("")
        setDate("")
        setSelTransDet("")
        setLoanType("")
        settotalloanAmount("")
        setNewDate("")
        setExpiryDate("")
        setYears(0)
        setInterest(0)
        setProcessingFee(0)
        setStampingFee(0)
        setInsurance(0)
        SetLeagalFee(0)
        settMembershipFee(0)
        setUpfrontDeduction(0)
        setServicePercentage(0)
        setServiceCharges(0)
        setCheckbox(false)
    };
    useEffect(()=>{
        if (loanCheckbox==false && isLoanSel==true){
            console.log("we are here")
            setChequeDate('')
            setBankName('')
            setChequeNo('')
        }

    },[loanCheckbox , isLoanSel])

    // Function For Modals----------------------------------------------------------------
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        if(isModalOpen) {
            setSelectedLoan(null);
            setPenNarration('');
            setAmount('');
            setSelectedOption('');
        }
    };
    // End functions for modal----------------------------------------------------------------

    const handleSubmit = (event) => {
        event.preventDefault();
        // Implement your API call logic here
        setModalLoading(true); // Show loader while processing API call
        

        const { loanid } = selectedLoan; // Extract loan ID from selected loan
        const type = selectedOption; // Use selected penalty/rebate type
        const amountValue = parseFloat(amount); // Convert amount to a floating-point number

        // Check if all required data is available
        if (!loanid || !type || isNaN(amountValue)) {
            // Handle missing data (optional)
            return;
        }

        // Construct the API payload
        const payload = {
            loan_id: loanid,
            type: type,
            amount: amountValue,
            memberid: memberNo,
            narration: penNarration,
            created_by: localStorage.getItem('username')
        };

        CustomAxios.post(`oasis/penalty_rebate/`, payload)
        .then(response => {
            // Handle API response as needed
            setLoanList(response.data.loan)
            setLoanTrans(response.data.transdetails)
            setModalLoading(false); // Hide loader
            toggleModal(); // Close modal
            })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setAmount('');
        setTotalPayableAmount('');
    };

    // Function to handle input field change
    const handleAmountChange = (event) => {
        setAmount(event.target.value);
        const inputAmount = event.target.value
        let totalAmount;
        if (selectedOption === 'Pen') {
            totalAmount = parseInt(selectedLoan.loanbalanceamount) + parseInt(inputAmount);
        } else {
            totalAmount = parseInt(selectedLoan.loanbalanceamount) - parseInt(inputAmount);
        }
        setTotalPayableAmount(totalAmount);
    };

    const handlePenNarrationChange = (event) => {
        setPenNarration(event.target.value);
    };


    // const api = `${process.env.REACT_APP_OASIS_BE_URL}oasis/`
    useEffect(()=>{
        let member_role = JSON.parse(window.sessionStorage.getItem('user_details'))
        if (member_role && member_role["member_role"]=="member"){
            navigate("/login")
        }
        // console.log("in exceu summary----",active_tab,newMemberData)
    })

    const navigate = useNavigate();
    let dateFormate = ""
    
    useEffect(() => {
        //console.log(newdate)
        const exp_date = new Date(date);
        //console.log(exp_date)
        const monthNumber = exp_date.getMonth() + 1;
        const dateNumber = exp_date.getDate();
        const formattedDate = `${(dateNumber < 10 ? '0' : '')+dateNumber}-${(monthNumber < 10 ? '0' : '') + monthNumber}-${exp_date.getFullYear()+parseInt(years, 10)}`;
        setExpiryDate(formattedDate)
        //console.log(formattedDate)

        
    }, [date, years])

    useEffect(() => {
        const fetchBanksAndTypes = async () => {
            setLoading(true);
            try {
                const bankResponse = await CustomAxios.get(`oasis/form_details/`);
                setBanks(bankResponse.data.bankName); // Assuming the response structure
                //console.log("bank info", bankResponse.data.bankName);
            } catch (error) {
                console.error("Error fetching banks:", error);
            }
            setLoading(false);
        };
        fetchBanksAndTypes();
    }, []);


    useEffect(() => {
        const deduct = parseInt(processingFee, 10) + parseInt(stmpingFee, 10) + parseInt(Insurance, 10) + parseInt(LegalFee, 10) +parseInt(membershipFee, 10) +parseInt(upfrontDeduction, 10) +parseInt(servicePercentage, 10) +parseInt(serviceCharges, 10)
        const chq_amt = totalLoanAmount - deduct
        setDeduction(deduct)
        setChequeAmount(chq_amt)

    },[processingFee, stmpingFee, Insurance, LegalFee, membershipFee, upfrontDeduction, servicePercentage, serviceCharges, totalLoanAmount])

    useEffect(() => {
        const dateObj = new Date(date[0]);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear() % 100;
        console.log(month, year)
        if (loanType === 1) {
            setNarration("Education Loan" + " " + month +"/"+year);
        } else if (loanType === 2) {
            setNarration("Personal Loan"+ " " + month +"/"+ year);
        } else if (loanType === 3) {
            setNarration("Housing Loan"+ " " + month +"/"+ year);
        } else if (loanType === 4) {
            setNarration("Business Loan"+ " " + month +"/"+ year);
        } else if (loanType === 5) {
            setNarration("Angkasa Loan"+ " " + month +"/"+ year);
        } else if (loanType === 6) {
            setNarration("Angkasa Tung"+ " " + month +"/"+ year);
        }
        else {
            setNarration("");
        }
    }, [loanType, date])

    useEffect(() => {
        if(checkbox){
            setFactor("Reducing Balance");

            const rate = interest/100;    // Monthly interest rate (annual interest rate divided by 12)
            const nper = years;      // Number of periods (e.g., number of months for a loan)
            const npermonth = nper * 12;
            const pv = totalLoanAmount;    // Present value (loan amount)
            // Calculate the payment
            const payment = pmt(rate, nper, pv);
            const roundedPayment = Math.abs(Math.round(payment));
            // Calculate total period amount
            const totPeriodAMt = roundedPayment * (npermonth / 12);

            // Calculate total interest amount
            const ppm = (parseFloat(totalLoanAmount)/(parseInt(years) *12)).toFixed(2)
            console.log(ppm)
            const iamt = Math.abs(totPeriodAMt - pv);
            const ipm = ((parseFloat(iamt)/(parseFloat(years) *12))).toFixed(2)
            console.log(ipm)
            const total_la = parseFloat(totalLoanAmount) + parseFloat(iamt)
            const total_la_pm = parseFloat(ppm) + parseFloat(ipm)

            setPrincipalPM(ppm)
            setInterestAmt(iamt)
            setInterestPM(ipm)
            setLoanAmtMon(total_la_pm)
            setLoandetails([{"header":"Principal", "Full Tenure":parseFloat(totalLoanAmount).toFixed(2), "Per Month":parseFloat(ppm).toFixed(2)},{"header":"Interest", "Full Tenure":parseFloat(iamt).toFixed(2), "Per Month":parseFloat(ipm).toFixed(2)},{"header":"Total Loan", "Full Tenure":parseFloat(total_la).toFixed(2), "Per Month":parseFloat(total_la_pm).toFixed(2)}]) 

        }
        else {
            setFactor("Straight Line");
            const ppm = (parseFloat(totalLoanAmount)/(parseInt(years) *12)).toFixed(2)
            console.log(ppm)
            const iamt = parseFloat(totalLoanAmount)*(parseFloat(interest)/100)*parseFloat(years)
            const ipm = (parseFloat(iamt)/(parseFloat(years) *12)).toFixed(2)
            console.log(ipm)
            const total_la = parseFloat(totalLoanAmount) + parseFloat(iamt)
            const total_la_pm = parseFloat(ppm) + parseFloat(ipm)
            setPrincipalPM(ppm)
            setInterestAmt(iamt)
            setInterestPM(ipm)
            setLoanAmtMon(total_la_pm)
            setLoandetails([{"header":"Principal", "Full Tenure":parseFloat(totalLoanAmount).toFixed(2), "Per Month":parseFloat(ppm).toFixed(2)},{"header":"Interest", "Full Tenure":parseFloat(iamt).toFixed(2), "Per Month":parseFloat(ipm).toFixed(2)},{"header":"Total Loan", "Full Tenure":parseFloat(total_la).toFixed(2), "Per Month":parseFloat(total_la_pm).toFixed(2)}]) 
        }
        

    },[totalLoanAmount, interest, years, checkbox])


    
    const handleMaster = (e) => {
        e.preventDefault();
        if(errors.member==="" && errors.guarentor==="" && errors.loanId==="") {
            //setExpiryDate(newdate)
            if (date) {
                dateFormate = date.map(item => {
                    const originalDate = new Date(item);
                    const year = originalDate.getFullYear();
                    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
                    const day = String(originalDate.getDate()).padStart(2, '0');
                
                    let formattedDate = `${day}-${month}-${year}`;
                    return formattedDate
                })
            }
            //console.log(dateFormate[0])
            setNewDate(dateFormate[0])
            setToggle("Loan Details")
            setProceedWithLoanDetails(true)
            // dispatch(updateMasterInfo({ payload: payload }))
       }
    }


const handleChequedate = (date) => {
    if (date) {
        const originalDate = new Date(date);
                    const year = originalDate.getFullYear();
                    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
                    const day = String(originalDate.getDate()).padStart(2, '0');
                
                    let formattedDate = `${day}-${month}-${year}`;
                    return formattedDate
    }
}
const reconstruct=(date)=>{
    const [day, month, year] = date.split('-');

// Create a new Date object with the parsed values
const dateObject = new Date(`${year}-${month}-${day}T00:00:00Z`);

// Format the date to the desired format
const formattedDateString = dateObject.toISOString();
return formattedDateString
}
    const handleLoan = (e) => {
        e.preventDefault();
        //console.log("here")

        if (!memberNo) {
            setShowMessage(true);
            return;
        }
        console.log(new Date(newdate))
        console.log(expirydate)
        let formattedchedate = ""
        if (loanCheckbox){
        const year = chequedate[0].getFullYear();
        const month = String(chequedate[0].getMonth() + 1).padStart(2, '0');
        const day = String(chequedate[0].getDate()).padStart(2, '0');
        const hours = String(chequedate[0].getHours()).padStart(2, '0');
        const minutes = String(chequedate[0].getMinutes()).padStart(2, '0');
        const seconds = String(chequedate[0].getSeconds()).padStart(2, '0');
        formattedchedate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`}
        let payload
        if (isLoanSel==true){
        console.log("we are here")
            payload = {
            memberid: memberNo,
            loanid: parseInt(LoanNo),
            loantype: parseInt(loanType),
            transdate:reconstruct( newdate),
            transdetailsid:selTransDet,
            totalLoanAmount: parseFloat(totalLoanAmount),
            principalamount:parseFloat(totalLoanAmount),
            noofinstalment:parseInt(years,10)*12,
            rateofinterest:parseFloat(interest),
            interestamount:parseFloat(InterestAmt).toFixed(2),
            legalfee:parseInt(LegalFee),
            processingfee:parseInt(processingFee),
            stampingfee:parseInt(stmpingFee),
            insuranceamount:parseFloat(Insurance),
            membershipfee:parseInt(membershipFee),
            angkasaupfrontdeduction:parseInt(upfrontDeduction),
            serviceinterestrate:parseFloat(servicePercentage),
            servicecharge:parseInt(serviceCharges),
            loanstartdate:reconstruct(newdate),
            loanexpirydate:reconstruct(expirydate),
            emiamount:parseFloat(loanAmtMon).toFixed(2),
            narration:narration,
            amountbeforededuction:parseFloat(totalLoanAmount)+parseFloat(InterestAmt),
            amountafterdeduction:parseFloat(totalLoanAmount),
            deduction:parseInt(deduction),
            diminishingbalance:checkbox,
            chequeamount:parseFloat(chequeAmount),
            bankname:bankname,
            chequeno:chequeNo,
            chequedate:formattedchedate,
            interestamountpermonth:0,
            principleamountpermonth:0,
            transtotalamount:parseFloat(totalLoanAmount),
            loanbalanceamount:parseFloat(totalLoanAmount)+parseFloat(InterestAmt)

        }}
        else{
            payload = {
                memberid: memberNo,
                loanid: parseInt(LoanNo),
                loantype: parseInt(loanType),
                transdate:reconstruct( newdate),
                totalLoanAmount: parseFloat(totalLoanAmount),
                principalamount:parseFloat(totalLoanAmount),
                noofinstalment:parseInt(years,10)*12,
                rateofinterest:parseFloat(interest),
                interestamount:parseFloat(InterestAmt).toFixed(2),
                legalfee:parseInt(LegalFee),
                processingfee:parseInt(processingFee),
                stampingfee:parseInt(stmpingFee),
                insuranceamount:parseFloat(Insurance),
                membershipfee:parseInt(membershipFee),
                angkasaupfrontdeduction:parseInt(upfrontDeduction),
                serviceinterestrate:parseFloat(servicePercentage),
                servicecharge:parseInt(serviceCharges),
                loanstartdate:reconstruct(newdate),
                loanexpirydate:reconstruct(expirydate),
                emiamount:parseFloat(loanAmtMon).toFixed(2),
                narration:narration,
                amountbeforededuction:parseFloat(totalLoanAmount)+parseFloat(InterestAmt),
                amountafterdeduction:parseFloat(totalLoanAmount),
                deduction:parseInt(deduction),
                diminishingbalance:checkbox,
                chequeamount:parseFloat(chequeAmount),
                bankname:bankname,
                chequeno:chequeNo,
                chequedate:formattedchedate,
                interestamountpermonth:0,
                principleamountpermonth:0,
                transtotalamount:parseFloat(totalLoanAmount),
                loanbalanceamount:parseFloat(totalLoanAmount)+parseFloat(InterestAmt)
    
            }

        }
        console.log(payload)
        setLoading(true)
        CustomAxios.post(`oasis/loanview/`, payload)
        //axios.post('http://127.0.0.1:8000/oasis/loanview/', payload)
          .then(response => {
            // Handle API response as needed
            //console.log('API Response:', response);
            const responseData = response.data;
            //console.log("ResponseData", responseData);
            // setTransDetails(responseData);
            // setResetLinkSent(true)
            setLoanRes(responseData.success)
            setloanNoForGuarantor(responseData.success.loanid)
            setLoading(false)
            setShowMessage(false);
            })
           
            .catch(error => {
                console.error('Error:', error);
            });
        //setToggle("Gaurantour Details")
        // dispatch(updateMasterInfo({ payload: payload }))
    }

    const guarantoHandle=async(e)=>{
        e.preventDefault();
        const GuarantorPayload={
            "memberid":memberNo,
            "loanno":parseInt(loanNoForGuarantor),
            "guarantorid":gaurantorMemberNo,
            "guarantoricno":gaurantorIcNo,
        
        }
        setLoading(true)
        const responses=await CustomAxios.post(`oasis/guarantor/`,GuarantorPayload)
        setGaurantorsuccess(true)
        setLoading(false)

      
    }
    const GetMemberDetails = async(e, num)=>{
        e.preventDefault()
        getLoanGuarantor(memberNo)
        if(!isNaN(e.target.value) && Number.isInteger(parseInt(e.target.value))||(gaurantorIcNo!="")||(memberName!="")||(gaurantorMemberName!="")){
            let payload
            if (memberNo!=""){
                payload = {
                    "memberid":parseInt(memberNo)
                }
            }
            if (gaurantorMemberNo!=""){
                payload = {
                    "memberid":parseInt(gaurantorMemberNo)
                }
            }
                if (gaurantorIcNo!=""){
                    payload={
                    "icno":gaurantorIcNo}
                    //console.log(payload)
                }
                    if (memberName!=""){
                        //console.log(memberName)
                        payload={
                        "name":memberName}
                    }
                    if (gaurantorMemberName!=""){
                        payload={
                        "name":gaurantorMemberName}
                    }
            

            try{
                errors.member= "";
                setLoading(true);
                CustomAxios.post(`oasis/getmember/`, payload).then((data)=>{
                    // console.log(data)
                    let res_data = data.data
                    //setCurrentMember([res_data])
                    if (((gaurantorMemberNo!="")||(gaurantorMemberName!="")||(gaurantorIcNo!=""))&&((num=="gaurantorMemberNo")||(gaur==1))){
                    //console.log("infirst")
                    //setMemberName("")
                    //setMemberNo("")
                    setGaurantorIcNo(data.data.newicno)
                    setGaurantorMemberNo(data.data.memberid)
                    setGaurantorMemberName(data.data.membername)}
                    if(((memberNo!="")||(memberName!=""))&&num=="memberno"&&toggle!="Guarantor Details"){
                    //console.log("in second")
                    //console.log(toggle)
                    setMemberName(data.data.membername)
                    setMemberNo(data.data.memberid)}
                setLoading(false);
                    
                }).catch(err=>{
                    //console.error(err)
                    setLoading(false);
                errors.member= "not available";
                    
                })
            }
            catch(err){
                //console.error(err)
                errors.member= "not available";
            }
        }
        else{
            setCurrentMember([])
        }
    }
    const GetGMember = async(e, num)=>{
        e.preventDefault()
        if(toggle=="Guarantor Details"){
            let payload
            if (gaurantorMemberNo!=""){
                payload = {
                    "memberid":parseInt(gaurantorMemberNo)
                }
            }
                if (gaurantorIcNo!=""){
                    payload={
                    "icno":gaurantorIcNo}
                    //console.log(payload)
                }
                    if (gaurantorMemberName!=""){
                        payload={
                        "name":gaurantorMemberName}
                    }
            

            try{
                errors.member= "";
                setLoading(true);
                CustomAxios.post(`oasis/getmember/`, payload).then((data)=>{
                    // console.log(data)
                    let res_data = data.data
                    //setCurrentMember([res_data])
                    if (((gaurantorMemberNo!="")||(gaurantorMemberName!="")||(gaurantorIcNo!=""))&&((num=="gaurantorMemberNo")||(gaur==1))){
                    //console.log("infirst")
                    //setMemberName("")
                    //setMemberNo("")
                    setGaurantorIcNo(data.data.newicno)
                    setGaurantorMemberNo(data.data.memberid)
                    setGaurantorMemberName(data.data.membername)}
                setLoading(false);
                    
                }).catch(err=>{
                    //console.error(err)
                    setLoading(false);
                errors.member= "not available";
                    
                })
            }
            catch(err){
                //console.error(err)
                errors.member= "not available";
            }
        }
        else{
            setCurrentMember([])
        }
    }
    const handleclick = (e,num) => {
        e.preventDefault()
        if(e.key=="Enter"){
            //console.log("jkhskfjv")
            GetMemberDetails(e,num)
        }
        if(e.key=="Enter"&&toggle=="Guarantor Details"){
            //console.log("gaurantor")
            setGaur(1)
            GetGMember(e,num)   
        }
    }
    const handleMemberChange = (e) => {
        setMemberName("")
        setGaurantorIcNo("")
        setGaurantorMemberName("")
        setGaurantorMemberNo("")
        setMemberNo(e.target.value)
    }
    const handleMNameChange = (e) => {
        setMemberName(e.target.value)
        setGaurantorIcNo("")
        setGaurantorMemberName("")
        setGaurantorMemberNo("")
        setMemberNo("")
    }
    const handleGMemberNoChange = (e) => {
        setGaurantorIcNo("")
        setGaurantorMemberName("")
        setGaurantorMemberNo(e.target.value)
    }
    const handleGMemberNameChange = (e) => {
        setGaurantorIcNo("")
        setGaurantorMemberName(e.target.value)
        setGaurantorMemberNo("")
    }
    const handleGICNoChange = (e) => {
        setGaurantorIcNo(e.target.value)
        setGaurantorMemberName("")
        setGaurantorMemberNo("")
    }
    const dateformat = (rowData) => {
        if(rowData.loanstartdate!=null){
        const date = rowData.loanstartdate!=null && new Date(rowData.loanstartdate)
        return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();}
    }
    const dateformatexp = (rowData) => {
        if(rowData.loanexpirydate != null){
            const date = rowData.loanexpirydate != null && new Date(rowData.loanexpirydate)
        return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        }
        
    }

    const [ColumnVisible, setColumneVisible] = useState([

        { field: "loanid", header: "Loan#", visible: true },
        { field: "loanstartdate", header: "LoanDate", visible: true, body: dateformat },
        { field: "principalamount", header: "Principal", visible: true },
        { field: "noofinstalment", header: "#inst", visible: true },
        { field: "loanexpirydate", header: "Expiry", visible: true, body: dateformatexp },
        { field: "loanbalanceamount", header: "LoanBalance", visible: true },
        { field: "total_penalty", header: "TotalPenalty", visible: true },
        { field: "total_rebate", header: "TotalRebate", visible: true },
    ])
    const [GuarantorColumnVisible, setGuarantorColumnVisible] = useState([
        { field: "guarantorid", header: "Guarantor", visible: true, style: { width: '80px' } },
        { field: "guarantorname", header: "MemberName", visible: true, style: { width: '350px' } },
        { field: "guarantoricno", header: "NRIC (New/Old)", visible: true },
        { field: "loanno", header: "Loan#", visible: true },
    ])
    // const [mgColumnVisible, setmgColumnVisible] = useState([
    //     { field: "memberid", header: "memberID", visible: true, style: { width: '80px' } },
    //     { field: "guarantorname", header: "MemberName", visible: true, style: { width: '350px' } },
    //     { field: "guarantoricno", header: "NRIC (New/Old)", visible: true },
    //     { field: "loanno", header: "Loan#", visible: true },
    // ])
    const getLoanGuarantor = async (memberId) => {
        try {
            let payload = { memberid: memberId };
            if(memberId){
                const response = await CustomAxios.get(`oasis/specificGuarantor/?mid=${parseInt(memberId)}`);
                //console.log("gggggggggggggggg", response.data);
                setLoanList(response.data.loan)
                setGuarantorList(response.data.guarantor)
                setguarantorForList(response.data.memberGuarantor)
                setLoanIdList(response.data.loanIdList.map(loan => loan.loanid))
                setLoanTrans(response.data.transdetails)
            }

        } catch (error) {
            console.error("Error fetching member details:", error);
        }
    }

    useEffect(()=> {
        const loanType = ["Education Loan1", "Personal Loan2","Housing Loan3", "Business Loan4", "Angkasa Loan5", "Angkasa Tung6"];
        const uniqueLoanType = []
        //const uniqueLoanType = loanList.filter((obj, index) => index === loanList.findIndex(o => obj.loantype === o.loantype)).map((({loantype})=> ["Education Loan", "Personal Loan", "Housing Loan", "Business Loan", "Angkasa Loan", "Angkasa Tung"][loantype - 1]))
        setUserNotTakenLoanTypes(loanType.filter(loantype => !uniqueLoanType.includes(loantype.slice(0, loantype.length - 1))))
    },[loanList])

    useEffect(() => {
       if(!LoanNo || !date[0] || !loanType || !totalLoanAmount) 
           setProceedWithLoanDetails(false)
    },[LoanNo,date[0],loanType,totalLoanAmount])

    const handlePenaltyRebate = (rowData, col) => {
        console.log("inside handle penalty rebate function--------------", rowData, col);
        setSelectedLoan(rowData);
        toggleModal();
    };

    const handleLoanIdChange = async(e) => {
        if(LoanNo!==""){
            const response = await CustomAxios.get(`oasis/loanid_check/?loanid=${parseInt(LoanNo)}`);
            if(response.data.status=="Loan id Exist"){
            setErrors(prev => ({...prev, loanId: "Loan id is already Exist. Please provide a unique id."}))
        }else{
            setErrors(prev => ({...prev, loanId: ""}))
        }
        }
    }

    return (
        <Box sx={{width:"100%", marginTop:"60px"}}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* <LinkHeader/> */}
                {isLoading?
                    <Stack rowGap="10px" sx={{width:"100%",height:"87vh",alignItems:"center",justifyContent:"center"}}>
                        <Typography>Please Wait</Typography>
                        <ProgressBar mode="indeterminate" style={{ width:"25%", height: '6px' }}/>
                    </Stack> 
                :<Box sx={{display:"flex",flexDirection:"column",height:isMobile?"100vh":"calc(100vh - 148px)" }}>
                     <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                     <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                         <Box sx={{maxWidth:"100%",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                            <AccountBalanceOutlinedIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                             </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#1E293B",width:"100%"}}>Loan</Typography>
                        </Box>
                        {/* <Button  onClick={searchMemberStatus?()=>clearAllStates():(e)=> handleSignup(e)}  variant="contained"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"#288d2c", textTransform: "none"}}><PersonAddAltOutlinedIcon sx={{width:"24px",height:"24px",color:"#FFFFF"}}/> <Typography sx={{color:"#FFFFFF",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}}>Add Member</Typography></Button> */}
                    </Box>
                </Box>
                <Box sx={{height:"64px",display:"flex",borderBottom:"1px solid #CBD5E1",margin:"0px 20px"}}>
                    <Box  sx={{height:"64px",display:"flex",alignSelf:"stretch",padding:"20px",background:"#FFFFFF",borderBottom:active=="Create Loan"?"2px solid #288d2c":"1px solid #CBD5E1",cursor:"pointer"}} onClick={()=>setActive("Create Loan")}>
                         <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"700",lineHeight:"24px",color:active=="Create Loan"?"#1E293B":"#64748B"}}>Create Loan</Typography>
                    </Box>
                    <Box sx={{height:"64px",display:"flex",alignSelf:"stretch",padding:"20px",background:"#FFFFFF",borderBottom:active=="Loan List"?"2px solid #288d2c":"1px solid #CBD5E1",cursor:"pointer"}}  onClick={()=>setActive("Loan List")}>
                         <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"700",lineHeight:"24px",color:active=="Loan List"?"#1E293B":"#64748B"}}>Loan List</Typography>
                    </Box>
                </Box>
                    {active == "Create Loan" &&<Box >
                    <Box sx={{ display: "flex", gap: "20px",alignItems:"flex-end", justifyContent: "space-between" , padding: "20px 30px"}}>
                        <Box sx={{ display: "flex", gap: "20px",alignItems:"flex-end"}}>
                        <Box >
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Member No<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                            <InputText
                                value={memberNo}
                                type="number"
                                onChange={(e) => handleMemberChange(e)}
                                onKeyUp={(e)=>handleclick(e,"memberno")}
                                style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                            />
                        </Box>
                        <Box >
                            <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Member Name</Typography>
                            <InputText
                                value={memberName} 
                                onChange={(e) => handleMNameChange(e)}
                                onKeyUp={(e)=>handleclick(e,"memberno")}
                                style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                            />
                        </Box>
                        {errors.member!==""&&<Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px",color:"red",display:"flex",alignItems:"flex-end" }}>Please enter valid MemberId</Typography>}
                        </Box>
                        {/* <Box>
                        <Button component={Link} to="/loan_lists" sx={{textTransform: 'none', height: "48px", borderRadius: "4px", padding: "12px", backgroundColor: "#288d2c", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px", color: "#FFFFFF"}} variant="contained">Loan Lists Of All Members</Button>
                        </Box> */}
                    </Box>
                    <Box sx={{ display: "flex", gap: "40px", width: "100%", padding: "0px 30px" }}>
                        <Box sx={{ width: "300px", height: "60px", background: "#f8f9fa", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" ,borderBottom:toggle == "Master Information"?"2px solid var(--pallate-green-500, #288d2c)":"none"}} onClick={() => setToggle("Master Information")}>
                            <Typography > Master Information</Typography>
                        </Box>
                        <Box sx={{ width: "300px", height: "60px", background: "#f8f9fa", display: "flex", justifyContent: "center", alignItems: "center", cursor: proceedWithLoanDetails?"pointer":"not-allowed" ,borderBottom: proceedWithLoanDetails && toggle == "Loan Details"?"2px solid var(--pallate-green-500, #288d2c)":"none"}} onClick={() => proceedWithLoanDetails && setToggle("Loan Details")}>
                            <Typography> Loan Details</Typography>
                        </Box>
                        <Box sx={{ width: "300px", height: "60px", background: "#f8f9fa", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" ,borderBottom:toggle == "Guarantor Details"?"2px solid var(--pallate-green-500, #288d2c)":"none"}} onClick={() => setToggle("Guarantor Details")}>
                            <Typography>Guarantor Details</Typography>
                        </Box>
                    </Box>
                    {toggle == "Master Information" && <Box sx={memberNo==""&&memberName==""?{pointerEvents:"none",opacity:"0.5", padding: "20px 30px"}:{padding: "20px 30px"}}>
                        <form onSubmit={(e)=>handleMaster(e)}>
                        <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap", width: "100%",alignItems:"flex-end" }}>
                            <Box >
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Please Enter Loan No <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <InputText
                                    value={LoanNo}
                                    required
                                    type="number"
                                    onChange={(e) => setLoanNo(e.target.value)}
                                    onBlur={(e) => handleLoanIdChange(e)}
                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                />
                                {errors.loanId!==""&&<Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px",color:"red",display:"flex",alignItems:"flex-end",marginBottom:"-24px" }}>{errors.loanId}</Typography>}
                            </Box>                            
                            <Box >
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Date<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <Calendar required value={date[0]} onChange={(e) => setDate([e.value])} style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }} dateFormat="dd-mm-yy" placeholder="dd-mm-yyyy"
                                />
                            </Box>
                            <Box >
                                <input type="checkbox" checked={checkbox} onChange={(e) => setCheckbox(!checkbox)} /><Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Reducing Balance</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Loan Type<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <Select
                                    onChange={(e) => setLoanType(e.target.value)}
                                    displayEmpty
                                    required
                                    value={loanType}
                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                >
                                    <MenuItem value="" disabled>Select Loan Type</MenuItem>
                                    {userNotTakenLoanTypes.map((loanType) => <MenuItem value={parseInt(loanType[loanType.length-1])}>{loanType.slice(0, loanType.length-1)}</MenuItem>)}
                                </Select>
                            </Box>
                            <Box >
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Total Loan Amount<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <InputText
                                    value={totalLoanAmount}
                                    required
                                    type="number"
                                    onChange={(e) => settotalloanAmount(e.target.value)}
                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                />
                            </Box>
                            <Button type="submit" variant="contained" sx={{ border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor: "pointer"}}>Submit</Button>
                        </Box>

                        </form>
                        <Box sx={{marginTop:"20px"}}>
                            <FormLabel component="legend" sx={{ fontWeight: "700" }}>Active Loans</FormLabel>
                            <DataTable value={loanList} rows={5} style={{ backgroundColor: "#F1F5F9", height: loanList.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable selectionMode="single" onSelectionChange={(e) => setSelectedProduct(e.value)} onRowSelect={onRowSelect} onRowDeselect={onRowUnselect} scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                {ColumnVisible.filter(col => col.visible).map((col, ind) => <Column key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                                <Column key="penaltyRebateButton" body={(rowData, col) => (
                                    <Button onClick={() => handlePenaltyRebate(rowData, col)}>Penalty/Rebate</Button>
                                )} />
                            </DataTable>
                        </Box>
                        <Box sx={{marginTop:"20px"}}>
                            <FormLabel component="legend" sx={{ fontWeight: "700" }}>Guarantor For the Loans Above</FormLabel>
                            {selectedSD.length>0&&<DataTable value={selectedSD} rows={5} style={{ backgroundColor: "#F1F5F9", height: selectedSD.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                {GuarantorColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>}
                        </Box>
                        {/* <ThemeProvider theme={theme}> */}
                        <Modal
                            open={isModalOpen}
                            onClose={toggleModal}
                            aria-labelledby="penalty-rebate-modal-title"
                            aria-describedby="penalty-rebate-modal-description"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <div style={{ backgroundColor: 'white', padding: '20px', width: '600px' }}>
                                <Typography variant="h5" id="penalty-rebate-modal-title" gutterBottom>
                                    Penalty/Rebate Details
                                </Typography>
                                {/* Form */}
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Loan ID"
                                                value={selectedLoan && selectedLoan.loanid}
                                                fullWidth
                                                disabled
                                                margin="normal"
                                                InputProps={{
                                                    style:{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Principal Amount"
                                                value={selectedLoan && selectedLoan.principalamount}
                                                
                                                disabled
                                                margin="normal"
                                                InputProps={{
                                                    style:{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Current Loan Balance"
                                                value={selectedLoan && selectedLoan.loanbalanceamount}
                                                fullWidth
                                                disabled
                                                margin="normal"
                                                InputProps={{
                                                    style:{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Total Penalty"
                                                value={selectedLoan && selectedLoan.total_penalty}
                                                fullWidth
                                                disabled
                                                margin="normal"
                                                InputProps={{
                                                    style:{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Total Rebate"
                                                value={selectedLoan && selectedLoan.total_rebate}
                                                fullWidth
                                                disabled
                                                margin="normal"
                                                InputProps={{
                                                    style:{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Radio buttons for penalty and rebate */}
                                    <RadioGroup aria-label="penalty-rebate" row name="penalty-rebate" value={selectedOption} onChange={handleOptionChange}>
                                        <FormControlLabel value="Pen" control={<Radio />} label="Penalty" />
                                        <FormControlLabel value="Reb" control={<Radio />} label="Rebate" />
                                    </RadioGroup>

                                    {/* Input field for entering amount */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={`Enter ${selectedOption === 'Reb' ? 'Rebate' : 'Penalty'} Amount`}
                                                type="number"
                                                value={amount}
                                                onChange={handleAmountChange}
                                                disabled={!selectedOption}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Narration"
                                                value={penNarration}
                                                inputProps={{ maxLength: 20 }}
                                                onChange={handlePenNarrationChange}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Total Payable Amount"
                                                value={totalPayableAmount}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Submit button */}
                                    <Button type="submit" sx={{mt:2}} variant="contained" disabled={modalLoading}>
                                        {modalLoading ? <CircularProgress size={24} /> : 'Submit'}
                                    </Button>
                                </form>
                            </div>
                        </Modal>
                        {/* </ThemeProvider> */}
                        {/* <Box>
                            <FormLabel component="legend" sx={{ fontWeight: "700" }}>Guarantor For the Following Members[s]</FormLabel>
                            <DataTable value={guarantorForList} rows={5} style={{ backgroundColor: "#F1F5F9", height: guarantorForList.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                {mgColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                        </Box> */}
                    </Box>}



                    {toggle == "Loan Details" && 
                    <Box >
                    <Box sx={memberNo==""&&memberName==""?{pointerEvents:"none",opacity:"0.5",height:isMobile?"100vh":"calc(100vh - 84px)"}:{height:isMobile?"100vh":"calc(100vh - 84px)"}}>
                       {isLoading?
                        <Stack rowGap="10px" sx={{width:"100%",height:"87vh",alignItems:"center",justifyContent:"center"}}>
                            <Typography>Please Wait</Typography>
                            <ProgressBar mode="indeterminate" style={{ width:"25%", height: '6px' }}/>
                        </Stack> 
                        : <form onSubmit={(e)=> handleLoan(e)}><Box sx={{ display: "flex",height:isMobile?"100vh":"calc(100vh - 84px)"}}>
                             <Box sx={{ display: "flex",flexDirection:"column",gap: "20px", flexWrap: "wrap", width: "55%", padding: "20px 30px" }}>
                            <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Loan type:{loanType==1?"Education Loan":loanType==2?"Personal Loan":loanType==3?"Housing Loan":loanType==4?"Business Loan":loanType==5?"Angkasa Loan":"Angkasa Tung"}</Typography>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Loan No:{LoanNo}</Typography>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Issue date:{newdate}</Typography>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Loan Expiry:{years!=""?expirydate:""}</Typography>
                                <Box >
                                    <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Factor</Typography>
                                    <InputText
                                        value={factor}
                                        onChange={(e) => setFactor(e.target.value)}
                                       style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                <Box >
                                    <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Principal</Typography>
                                    <InputText
                                        value={totalLoanAmount}
                                        type="number"
                                        required
                                       style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                    />
                                </Box>
                                <Box sx={{ display: "flex" }}>

                                    <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Tenure<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                    <InputText
                                    required
                                        value={years}
                                        type="number"
                                        onChange={(e) => setYears(e.target.value)}
                                        style={{ padding: "12px", width: "100px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                    />
                                    <Typography sx={{ textAlign: "center", alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Years = </Typography>
                                    <InputText
                                        value={years*12}
                                        style={{ padding: "12px", width: "100px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                    />
                                    <Typography sx={{ textAlign: "center", alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Months</Typography>

                                </Box>
                            </Box>
                            <Box>
                                <Box >
                                    <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Interest<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                    <InputText
                                    required
                                        value={interest}
                                        type="number"
                                        onChange={(e) => setInterest(e.target.value)}
                                       style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box >
                                    <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Narration<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                    <InputText
                                        value={narration}
                                        required
                                        onChange={(e) => setNarration(e.target.value)}
                                       style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display:"flex",gap:"20px"}}>
                        {/* <Button type="submit" variant="contained" sx={{ display: "flex",height:"50px", width: "104px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} >Submit</Button> */}
                   {Object.keys(loanResponse).length>0&&<Typography sx={{marginTop:"20px", alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px",color:"var(--Light-Utility-Primary, #288d2c)" }} startIcon={<CheckOutlined/>}>Loan Applied successfully</Typography>}
                            </Box>
                            {showMessage && (
                <Typography sx={{ color: 'red' }}>Please enter a valid Member ID before submitting loan details.</Typography>
              )}
                        </Box>
                        <Box sx={{ display: "flex",width:"45%" }}>
                            <Box sx={{ border: "1px solid black" }}>
                                <Box sx={{ padding: "10px" }}>
                                    <Box sx={{ height: "30px", borderBottom: "1px solid black" }}>
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", textAlign: "right" }}>Principal</Typography>
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Processing Fee</Typography>
                                        <InputText
                                            value={processingFee}
                                            type="number"
                                            onChange={(e) => setProcessingFee(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Stamping Fee</Typography>
                                        <InputText
                                            value={stmpingFee}
                                            type="number"
                                            onChange={(e) => setStampingFee(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Insurance</Typography>
                                        <InputText
                                            value={Insurance}
                                            type="number"
                                            onChange={(e) => setInsurance(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Legal Fee</Typography>
                                        <InputText
                                            value={LegalFee}
                                            type="number"
                                            onChange={(e) => SetLeagalFee(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Membership Fee</Typography>
                                        <InputText
                                            value={membershipFee}
                                            type="number"
                                            onChange={(e) => settMembershipFee(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Upfront Deduction</Typography>
                                        <InputText
                                            value={upfrontDeduction}
                                            type="number"
                                            onChange={(e) => setUpfrontDeduction(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Service %</Typography>
                                        <InputText
                                            value={servicePercentage}
                                            type="number"
                                            onChange={(e) => setServicePercentage(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Service Charges</Typography>
                                        <InputText
                                            value={serviceCharges}
                                            type="number"
                                            onChange={(e) => setServiceCharges(e.target.value)}
                                            style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                        />
                                    </Box>
                                    <Box >
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", textAlign: "right" }}>Total Deduction:</Typography>
                                        <Typography sx={{ mt:"20px", alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", textAlign: "right" }}>Cheque Amount:</Typography>
                                    </Box>
                                </Box>
                                <Box>

                                </Box>

                            </Box>
                            <Box sx={{ border: "1px solid black" }}>
                                <Box sx={{ padding: "10px" }}>
                                    <Box sx={{ height: "30px", borderBottom: "1px solid black"}}>
                                        <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>{totalLoanAmount}</Typography>
                                    </Box>
                                    <Box sx={{ height: "30px", borderBottom: "1px solid black"}}>
                                    <Typography sx={{mt:"548px" ,alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>{deduction}</Typography>
                                    </Box>
                                    <Box sx={{ height: "30px"}}>
                                    <Typography sx={{mt:"10px", alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>{chequeAmount}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box sx={{ border: "1px solid black" }}>
                                <Box sx={{ padding: "10px" }}>
                                <Box >
                                <input type="checkbox" onChange={() => setLoanCheckbox(!loanCheckbox)} checked={loanCheckbox}/><Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>CHEQUE</Typography>
                                </Box>
                                {loanCheckbox&&<Box >
                                <Box >
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Cheque Date</Typography>
                                <Calendar required value={chequedate[0]} onChange={(e) => setChequeDate([e.value])} style={{ width: "337px", height: "36px", display: "flex", alignItems: "center" }} dateFormat="dd-mm-yy" placeholder="dd-mm-yyyy"
                                />
                            </Box>
                            <Box >
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Bank Name</Typography>
                                <Dropdown showClear value={bankname} filter label="Bank" style={{ width: "333px", height: "53px" }} placeholder='Bank *' onChange={(e)=>setBankName(e.target.value)} name="bank" options={banks} optionLabel='bankname'
                                    editable />
                            </Box>
                            <Box >
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Cheque No</Typography>
                                <InputText
                                    value={chequeNo}
                                    onChange={(e) => setChequeNo(e.target.value)}
                                    style={{ padding: "12px", width: "337px", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)", background: " var(--Light-Forms-Background, #FFF)" }}
                                />
                            </Box></Box>}
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: "24px", }}>
                                <div className="card">
                                    <DataTable value={loandetails} rows={10}  dataKey="sn" style={{ backgroundColor: "#F1F5F9" }} scrollable scrollHeight="650px" columnfinalMembersizeMode="expand" removableSort finalMembersizableColumns
                                        emptyMessage="No details found." >
                                        <Column field="header" header="" headerStyle={{ width: '500px' }} />
                                        <Column field="Full Tenure" header="Full Tenure" headerStyle={{ width: '500px' }}/>
                                        <Column field="Per Month" header="Per Month" headerStyle={{ width: '500px' }} />
                                    </DataTable>
                                </div>
                            </Box>
                            
                        </Box>
</Box>
                         <Box sx={{position:"sticky",bottom:0}}>
                 <Box sx={{height:"76px",width:"100%",display:"flex",justifyContent:"flex-end",gap:"24px",padding:"14px 30px",background:"#F1F5F9"}}>
                                    <Box sx={{width:"406px",display:"flex",gap:"24px"}}>
                                    <Button variant="text"  sx={{maxWidth:"201px",width:"100%",height:"48px",borderRadius:"4px",padding:"12px",display:"flex",gap:"8px",background:"transparent", textTransform: "none"}} onClick={()=>{onRowUnselect(); setToggle("Master Information")}}> <ClearIcon sx={{color:"#64748B"}}/><Typography sx={{color:"rgb(100, 116, 139)",fontSize:"16px",fontWeight:"400",lineHeight:"24px",fontFamily:"inter"}} >Clear</Typography></Button>
                                    <button type="submit" variant="contained"  style={{border:"none",color:"#FFFFFF", textTransform:"none",width:"204px",height:"48px",borderRadius:"4px",padding:"12px",background:"#288d2c", cursor: "pointer" }} >
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#FFFFFF"}} >Submit</Typography>
                                  </button>
                                    </Box>
                              
                </Box> 
                 </Box>
                       </form>}
                       </Box>
                      
</Box>
                    }




                    {toggle == "Guarantor Details" && <Box  sx={memberNo==""&&memberName==""?{pointerEvents:"none",opacity:"0.5", padding: "20px 30px"}:{padding: "20px 30px"}}>
                       {isLoading?
                    <Stack rowGap="10px" sx={{width:"100%",height:"87vh",alignItems:"center",justifyContent:"center"}}>
                        <Typography>Please Wait</Typography>
                        <ProgressBar mode="indeterminate" style={{ width:"25%", height: '6px' }}/>
                    </Stack> 
                       : <Box><form onSubmit={(e)=>guarantoHandle(e)}>
                        <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap", width: "100%", padding: "20px 0px",alignItems:"flex-end"}}>
                            <Box sx={{maxWidth:"280px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Member No<Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                <InputText
                                    value={gaurantorMemberNo}
                                    required
                                    type="number"
                                    onKeyUp={(e)=>handleclick(e,"gaurantorMemberNo")}
                                    onChange={(e) => handleGMemberNoChange(e)}
                                   style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                />
                            </Box>
                            <Box sx={{maxWidth:"280px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Member Name</Typography>
                                <InputText
                                    value={gaurantorMemberName}
                                    onChange={(e) => handleGMemberNameChange(e)}
                                    onKeyUp={(e)=>handleclick(e,"gaurantorMemberNo")}
                                   style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                />
                            </Box>
                            <Box sx={{maxWidth:"280px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>IC No</Typography>
                                <InputText
                                    value={gaurantorIcNo}
                                    required
                                    onChange={(e) => handleGICNoChange(e)}
                                    onKeyUp={(e)=>handleclick(e,"gaurantorMemberNo")}
                                   style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                />
                            </Box>
                            <Box sx={{maxWidth:"280px",width:"100%"}}>
                                <Typography sx={{ alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Loan No</Typography>
                                <InputText
                                    value={loanNoForGuarantor}
                                    required
                                    onChange={(e) => setloanNoForGuarantor(e.target.value)}
                                   style={{ padding: "8px 12px", width: "100%", borderRadius: "4px", border: "1px solid var(--Light-Forms-Border, #CBD5E1)",fontSize:"14px",maxWidth:"280px",width:"100%"}}
                                />
                            </Box>
                        <Button type="submit" variant="contained" sx={{ display: "flex",height:"50px", width: "204px", padding: "12px", justifyContent: "center", gap: "8px", borderRadius: "4px", background: "var(--Light-Utility-Primary, #288d2c)" }} >Add Guarantor</Button>
                        </Box>
                        </form>
                         {/* <Box sx={{marginTop:"20px"}}>
                         <FormLabel component="legend" sx={{ fontWeight: "700" }}>Active Loans</FormLabel>
                         <DataTable value={loanList} rows={5} style={{ backgroundColor: "#F1F5F9", height: loanList.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                             {ColumnVisible.filter(col => col.visible).map((col, ind) => <Column key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                         </DataTable>
                     </Box> */}
                         <Box sx={{marginTop:"20px"}}>
                             <FormLabel component="legend" sx={{ fontWeight: "700" }}>Guarantor For the Loans Above</FormLabel>
                             <DataTable value={guarantorList} rows={5} style={{ backgroundColor: "#F1F5F9", height: guarantorList.length > 0 ? "220px" : "100%", fontSize: "12px" }} scrollable scrollHeight="220px" columnfinalMembersizeMode="expand" >
                                 {GuarantorColumnVisible.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                             </DataTable>
                         </Box>
                         </Box>
                        }
                        {guarantorSuccess==true &&<Typography sx={{marginTop:"20px", alignSelf: "stretch", width: "200px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px",color:"var(--Light-Utility-Primary, #288d2c)" }} startIcon={<CheckOutlined/>}>Guarantor Added successfully</Typography>}
                        {errors.guarentor!==""&&<Typography sx={{ alignSelf: "stretch",  fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px",color:"red" }}>Please enter valid MemberId</Typography>}
                        </Box>}
</Box>}
{
    active =="Loan List"&&<LoanList/>
}
                </Box>}
            </Box>
        </Box>
    )
}