import { Logout, PersonPinCircle } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useRef,useState,useEffect} from"react"
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoneyIcon from '@mui/icons-material/Money';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import { updateSidebar } from "../redux/executiveReducer";
import "../styles/linkheader.css"

export const LinkHeader = () => {
    const navigate=useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    }; 
    const pathsWithNavBar = ["/add_member","/executive_summary","/Receipts"];
    // const [pageName,setPageName] = useState('')
    // useEffect(()=>{
    //     switch(location.pathname){
    //         case "/specificDeposit":
    //             setPageName("Specific Deposit Issurance")
    //             break;
    //         case "/payments":
    //             setPageName("Payments Update Information")
    //             break;
    //         case "/Receipts":
    //             setPageName("Receipt Master Information")
    //             break;
    //         case "/executive_summary":
    //             setPageName("Executive Summary")
    //             break;
    //         case "/loan":
    //             setPageName("Loan")
    //             break
    //         case "/account-statement":
    //             setPageName("Account Statement")
    //             break;
    //     }
    // },[])
    return (
        <Box sx={{ background: 'var(--pallate-green-500, #288d2c)', padding: "18px 16px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}> */}
                {/* <Box sx={{ color: "#fff" }} onClick={() => navigate(-1)}><ArrowBackIcon /></Box> */}
                {/* <Typography sx={{ fontSize: "20px", fontWeight: 600, lineHeight: "28px", color: "#fff" }}>{pageName}</Typography> */}
                <Box sx={{display:"flex",gap:"24px",alignItems:"center"}}>
                <IconButton onClick={()=>dispatch(updateSidebar(true))}><MenuIcon sx={{color:"#FFF"}}/></IconButton>
                <Typography sx={{ fontSize: "20px", fontWeight: 600, lineHeight: "28px", color: "#fff" }}>AIQ KOPERASI</Typography>
                </Box>
            {/* </Box> */}

            <Box sx={{ display: "flex", alignItems: "centr", gap: "16px" }}>
                   {!pathsWithNavBar.includes(location.pathname) &&<> 
                   {/* <Button component={Link} to="/executive_summary" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={< SummarizeIcon/>} >Executive Summary</Button> */}
                    {/* <Button component={Link} to="/specificDeposit" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<RequestQuoteIcon />}>Specific Deposit</Button>
                        <Button component={Link} to="/loan" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<MoneyIcon />}>Loan</Button>
                        <Button component={Link} to="/payments" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<MoneyIcon />}>Payments</Button>
                        <Button component={Link} to="/Receipts" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<ReceiptLongIcon />}>Receipts</Button>
                        <Button component={Link} to="/analysis"variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<BarChartIcon />}>Reporting</Button>
                        <Button component={Link} to="/add_member" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<PersonAddIcon />} >Add Member</Button> */}
                        <Button component={Link} to="/account-statement" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<AccountBalanceIcon />} >Account Statement</Button>
                        {/* <Button component={Link} to="/member_ledger" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<PersonPinCircle />} >Member Ledger</Button> */}
                        </>}
                        <Tooltip title="Logout" arrow>
                            <Button
                                sx={{ color: "#fff" }}
                                onClick={handleLogout}
                            >
                                <Logout />
                            </Button>
                        </Tooltip>
                    </Box>
        </Box>
    )
}
